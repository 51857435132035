const TeamList = [
  {
    category: "executive",
    role: "Co-President",
    name: "Daryl Lee",

    // email:"", linkedin:"",
  },
  {
    category: "executive",
    role: "Co-President",
    name: "Fiona Wahidin",
  },
  { category: "executive", role: "VP (Internal)", name: "Brandon Nathaniel" },
  { category: "executive", role: "VP (External)", name: "Zainul Punjani" },
  { category: "executive", role: "Secretary & Arc Delegate", name: "Sisi Zhu" },
  { category: "executive", role: "Treasurer", name: "Krishant" },

  { category: "directors", role: "Operations", name: "Than Tam" },
  { category: "directors", role: "Operations", name: "Tín Huỳnh" },
  {
    category: "directors",
    role: "Membership and Development",
    name: "Ashley Chong",
  },
  {
    category: "directors",
    role: "Membership and Development",
    name: "Shirene Yeoh",
  },
  {
    category: "directors",
    role: "Finance and Partnership",
    name: "Aisyah Farhanah",
  },
  {
    category: "directors",
    role: "Finance and Partnership",
    name: "Ha Viet Huong",
  },
  {
    category: "directors",
    role: "Information Technology",
    name: "William Djong",
  },
  {
    category: "directors",
    role: "Information Technology",
    name: "Wong Han Ming",
  },
  {
    category: "directors",
    role: "Research & Strategy",
    name: "Jasper Ahmad",
  },
  {
    category: "directors",
    role: "Research & Strategy",
    name: "Justin Lum",
  },
  { category: "directors", role: "Marketing Director", name: "Aleina Diabyg" },
  {
    category: "directors",
    role: "Marketing Director",
    name: "Mohamad Zikri Afham bin Hasnan",
  },
  // { category: "associates", team: "Membership & Development", role: "M&D Associate", name: "Yvonne Stiasny" },
  //   {
  //     category: "associates",
  //     team: "Membership & Development",
  //     role: "M&D Associate",
  //     name: "Josephus Nifahowuo Ziraluo",
  //   },
  //   {
  //     category: "associates",
  //     team: "Membership & Development",
  //     role: "M&D Associate",
  //     name: "Chua Yi Jing",
  //   },
  //   {
  //     category: "associates",
  //     team: "Membership & Development",
  //     role: "M&D Associate",
  //     name: "Ziyi Wang",
  //   },
  //   {
  //     category: "associates",
  //     team: "Membership & Development",
  //     role: "M&D Associate",
  //     name: "Wanning Cai",
  //   },
  //   {
  //     category: "associates",
  //     team: "Membership & Development",
  //     role: "M&D Associate",
  //     name: "Zoe Ong",
  //   },
  //   {
  //     category: "associates",
  //     team: "Membership & Development",
  //     role: "M&D Associate",
  //     name: "Rainbow Li",
  //   },
  //   {
  //     category: "associates",
  //     team: "Membership & Development",
  //     role: "M&D Associate",
  //     name: "Kathy Nguyen",
  //   },
  //   {
  //     category: "associates",
  //     team: "Marketing",
  //     role: "Marketing Associate",
  //     name: "Cen Shen",
  //   },
  //   {
  //     category: "associates",
  //     team: "Marketing",
  //     role: "Marketing Associate",
  //     name: "Jessica Sutandar",
  //   },
  //   {
  //     category: "associates",
  //     team: "Marketing",
  //     role: "Marketing Associate",
  //     name: "Kevin Mandira Limanta",
  //   },
  //   {
  //     category: "associates",
  //     team: "Marketing",
  //     role: "Marketing Associate",
  //     name: "Sing-Sing Zhang",
  //   },
  //   {
  //     category: "associates",
  //     team: "Marketing",
  //     role: "Marketing Associate",
  //     name: "Thip Kamloonwaysarach",
  //   },
  //   {
  //     category: "associates",
  //     team: "Marketing",
  //     role: "Marketing Associate",
  //     name: "Mia Dang",
  //   },
  //   {
  //     category: "associates",
  //     team: "Marketing",
  //     role: "Marketing Associate",
  //     name: "Zoe Goh",
  //   },
  //   {
  //     category: "associates",
  //     team: "Marketing",
  //     role: "Marketing Associate",
  //     name: "Diva Fahira Zen",
  //   },
  //   // { category: "associates", team: "Information Technology", role: "IT Associate", name: "Tim Zhang" },
  //   {
  //     category: "associates",
  //     team: "Information Technology",
  //     role: "IT Associate",
  //     name: "Emir Aditya Zen",
  //   },
  //   {
  //     category: "associates",
  //     team: "Information Technology",
  //     role: "IT Associate",
  //     name: "Hong Ze Zhang",
  //   },
  //   {
  //     category: "associates",
  //     team: "Information Technology",
  //     role: "IT Associate",
  //     name: "William Djong",
  //   },
  //   {
  //     category: "associates",
  //     team: "Information Technology",
  //     role: "IT Associate",
  //     name: "Fengyu Wang",
  //   },
  //   {
  //     category: "associates",
  //     team: "Information Technology",
  //     role: "IT Associate",
  //     name: "Justin Wu",
  //   },
  //   {
  //     category: "associates",
  //     team: "Information Technology",
  //     role: "IT Associate",
  //     name: "Abigail Limanuel",
  //   },
  //   {
  //     category: "associates",
  //     team: "Information Technology",
  //     role: "IT Associate",
  //     name: "Darian Lee ",
  //   },
  //   {
  //     category: "associates",
  //     team: "Operations",
  //     role: "Operations Associate",
  //     name: "Tin Arkar Maung",
  //   },
  //   {
  //     category: "associates",
  //     team: "Operations",
  //     role: "Operations Associate",
  //     name: "Charran Kethees",
  //   },
  //   {
  //     category: "associates",
  //     team: "Operations",
  //     role: "Operations Associate",
  //     name: "Eric Ryan Duong",
  //   },
  //   {
  //     category: "associates",
  //     team: "Operations",
  //     role: "Operations Associate",
  //     name: "Alexander Hekkenberg",
  //   },
  //   {
  //     category: "associates",
  //     team: "Operations",
  //     role: "Operations Associate",
  //     name: "Daryl Lee",
  //   },
  //   {
  //     category: "associates",
  //     team: "Operations",
  //     role: "Operations Associate",
  //     name: "Katrina Alchin",
  //   },
  //   {
  //     category: "associates",
  //     team: "Finance & Partnership",
  //     role: "F&P Associate",
  //     name: "Christopher Chong Hong Chiok",
  //   },
  //   {
  //     category: "associates",
  //     team: "Finance & Partnership",
  //     role: "F&P Associate",
  //     name: "Timothy Ng",
  //   },
  //   {
  //     category: "associates",
  //     team: "Finance & Partnership",
  //     role: "F&P Associate",
  //     name: "Stella Lautner",
  //   },
  //   {
  //     category: "associates",
  //     team: "Finance & Partnership",
  //     role: "F&P Associate",
  //     name: "Vivian Wong",
  //   },
  //   {
  //     category: "associates",
  //     team: "Finance & Partnership",
  //     role: "F&P Associate",
  //     name: "Axel Tanamas Librata",
  //   },
];

export default TeamList;
